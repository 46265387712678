import axios from "axios";

let endpoint = process.env.ENDPOINT;

// If "ENDPOINT_ALT" is provided, divide across origins for high capacity events.
if (
  process.env.ENDPOINT_ALT &&
  process.env.ENDPOINT_ALT !== "disabled" &&
  Math.random() > 0.5
) {
  endpoint = process.env.ENDPOINT_ALT;
}

const instance = axios.create({
  baseURL: endpoint
});

instance.interceptors.response.use(
  response => response,
  error => {
    let processedError = error?.response?.data?.error || error;
    processedError.originalError = error;
    return Promise.reject(processedError);
  }
);

console.log("config", {
  endpoint,
  ENDPOINT_ALT: process.env.ENDPOINT_ALT,
  ENDPOINT: process.env.ENDPOINT,
  FESTIVAL_FRONTEND_DOMAIN_PROTOCOL:
    process.env.FESTIVAL_FRONTEND_DOMAIN_PROTOCOL,
  FESTIVAL_FRONTEND_DOMAIN: process.env.FESTIVAL_FRONTEND_DOMAIN
});

const CACHE_EXPIRY = 1000 * 30;
const tenantCache = {};
const requestCache = {};
export const fetchTenantConfig = tenant => {
  if (tenant === "favicon.ico") {
    throw new Error("Invalid tenant");
  }

  return new Promise((resolve, reject) => {
    if (!tenant) {
      resolve({});
    } else if (requestCache[tenant]) {
      requestCache[tenant].push([resolve, reject]);
    } else if (tenantCache[tenant] && tenantCache[tenant].exp > Date.now()) {
      resolve(tenantCache[tenant].value);
    } else {
      requestCache[tenant] = [[resolve, reject]];
      axios
        .get(
          `${process.env.FESTIVAL_FRONTEND_DOMAIN_PROTOCOL}://${tenant}.${process.env.FESTIVAL_FRONTEND_DOMAIN}/config.json`
        )
        .then(r => {
          const pendingRequests = requestCache[tenant];
          delete requestCache[tenant];
          if (r.headers["content-type"].indexOf("application/json") === -1) {
            pendingRequests.forEach(([resolve, reject]) =>
              reject(new Error("Site not found"))
            );
          } else {
            tenantCache[tenant] = {
              exp: Date.now() + CACHE_EXPIRY,
              value: r.data
            };
            pendingRequests.forEach(([resolve, reject]) => resolve(r.data));
          }
        })
        .catch(e => {
          const pendingRequests = requestCache[tenant];
          delete requestCache[tenant];
          pendingRequests.forEach(([resolve, reject]) => reject(e));
        });
    }
  });
};

export const recordEvent = async (event, payload = {}, attempt = 0) => {
  try {
    await axios.post("/api/play", {
      event,
      payload: JSON.stringify(payload)
    });
  } catch (e) {
    console.log("Failed to record event", e);
    if (attempt < 3) {
      setTimeout(() => {
        recordEvent(event, payload, attempt + 1);
      }, Math.random() * 2000 + 1000);
    } else {
      console.log(" ==> not retrying; attempts exceeded");
    }
  }
};

export default instance;
